<template>
	<div class="p-grid" style="padding: 0rem 0rem 0rem 3rem">
		<div class="p-col-8">
			<Card>
				<template #title>{{ $t('Evaluation') }}</template>
				<template #content>
					<div class="p-grid" style="min-height: 210px;">
						<DataTable :value="data.details" rowGroupMode="rowspan" groupRowsBy="questionnaire" sortMode="single" sortField="questionnaire" >
							<Column field="questionnaire" :header="$t('Assessment Criteria')" headerStyle="width: 50%">
								<template #body="{data}">
									<strong>{{data['compliance_'+i18n.locale()]}}</strong>
									<br>
									{{data[i18n.locale()]}}
								</template>
							</Column>	
							<Column field="description_eval" :header="$t('Description')" headerStyle="width: 40%">
								<template #body="{data}">
									<strong v-if="data.type==1">{{ $t('Evaluation') }}: {{$t('Internal')}}</strong>
									<strong v-else>{{ $t('Evaluation') }}: {{$t('External')}}</strong>
									<br>
									<strong>{{data['action_'+i18n.locale()]}}</strong>
									<br>
									{{ data.description }} 
								</template>
							</Column>
							<Column :header="$t('PDF')" headerStyle="width: 10%">
								<template #body="{data}">
									<Button :label="$t('View')" icon="pi pi-file-pdf" class="p-button-secondary p-mr-2 p-mb-2"  @click="ViewPDF(data.pdf)" v-if="data.pdf"/>
								</template>
							</Column>
						</DataTable>
					</div>
				</template>
			</Card>
		</div>
		
		<div class="p-col-4" v-if="step>0">
			<Card>
				<template #title>{{ $t('Analysis and Planning') }}</template>
				<template #content>
					<div class="p-grid" style="min-height: 210px;">
						<div class="p-col-3"><strong>{{$t('Cause')}}:</strong></div>
						<div class="p-col-9">{{ data['cause_clasif_'+i18n.locale()] }}</div>
						<div class="p-col-12">{{ data.cause }}</div>
						<div class="p-col-12">
							<strong>{{$t('Action description')}}:</strong>
							{{ data.description }}
						</div>
						<div class="p-col-2"><strong>{{$t('Impact')}}:</strong></div>
						<div class="p-col-3">{{ data['impact_'+i18n.locale()] }}</div>
						<div class="p-col-2"><strong>{{$t('Urengence')}}:</strong></div>
						<div class="p-col-3">{{ data['urengence_'+i18n.locale()] }}</div>
						<div class="p-col-2">
							<Tag :style="'width: 100%; height: 30px; background-color: #'+data.value_color">
								{{data.value}}
							</Tag>
						</div>
						<div class="p-col-3"><strong>{{$t('Responsible')}}:</strong></div>
						<div class="p-col-9">{{ data.responsible }}</div>
						<div class="p-col-3"><strong>{{$t('Date')+' '+$t('start')+' - '+$t('end')}}:</strong></div>
						<div class="p-col-9">
							{{ data['date_start_'+i18n.locale()] }} - {{ data['date_end_'+i18n.locale()] }}
						</div>
					</div>
				</template>
			</Card>
		</div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	export default {
		props: {
			data: {
				type: Object,
				default: null,
			},
			step: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				i18n: null,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
		},
	}
</script>